<section class="background-gradient">
  <div class="container p-4">
    <div class="row">
      <div class="col-lg-12">
        <h2 class="text-white text-center p-4">Media</h2>
      </div>
    </div>
  </div>
</section>
<section class="team">
    <div class="container-fluid p-5">
        <div class="row">
            <div class="col-lg-12">
                <ul class="nav nav-pills flex-column flex-sm-row align-items-start" id="myTab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link link active" data-toggle="tab" role="button" (click)="filterMedia(null)"
                            aria-controls="all" aria-selected="true">All</a>
                    </li>
                    <li class="nav-item" *ngFor="let group of $groups | async">
                        <a class="nav-link link" data-toggle="tab" role="button" (click)="filterMedia(group)"
                            aria-controls="group" aria-selected="false">{{group}}</a>
                    </li>
                </ul>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 p-3"
                *ngFor="let media of $filteredMedia | async">
                <div class="card shadow">
                    <div class="card-body h-100">
                        <span class="badge badge-secondary card-badge" *ngIf="media.new">New</span>
                        <h5 class="card-title"><img *ngIf="media.sponsor" src="{{media.sponsor}}"
                                class="rounded mr-2 mb-1" height="30px"> {{media.title}}</h5>
                        <p class="card-text text-grey">{{media.description}}</p>
                        <ng-container
                            *ngTemplateOutlet="setTemplateReference(media.publicationType);context:{data: media}">
                        </ng-container>
                    </div>
                    <ul *ngIf="media.attachments" class="list-group list-group-flush">
                        <a href="{{attachment.url}}" target="_blank" *ngFor="let attachment of media.attachments"
                            class="list-group-item list-group-item-action"><span><i class="fa {{attachment.iconClass}} fa-custom pr-2 align-middle"></i>
                            {{attachment.name}}</span>
                        </a>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<ng-template #video let-data="data">
    <div class="row justify-content-center">
        <div class="col-lg-12">
            <video controls class="rounded" width="100%" height="100%" poster="{{data.placeholderUrl}}">
                <source src="{{data.mediaUrl}}" type="{{data.mediaType}}">
                Your browser does not support the video tag.
            </video>
        </div>
    </div>
</ng-template>
<ng-template #embeded let-data="data">
    <div class="row justify-content-center">
        <div class="col-lg-12" style="padding:56.25% 0 0 0;position:relative;">
            <iframe [src]="sanitizeUrl(data.mediaUrl)" style="position:absolute;top:0;left:0;width:100%;height:100%;padding:12px;" frameborder="0" allowfullscreen></iframe>
        </div>
    </div>
</ng-template>
<ng-template #audio let-data="data">
    <div class="row justify-content-center">
        <div class="col-lg-8 col-md-12 col-sm-12">
            <img *ngIf="data.placeholderUrl" src="{{data.placeholderUrl}}" class="rounded" width="100%">
            <audio controls class="controls">
                <source src="{{data.mediaUrl}}" type="{{data.mediaType}}">
                Your browser does not support the audio element.
            </audio>
        </div>
    </div>
</ng-template>
<ng-template #link let-data="data">
    <a class="text-secondary link" href="{{data.mediaUrl}}" target="_blank">Read Now <i
            class="fa fa-arrow-right"></i></a>
</ng-template>
<ng-template #document let-data="data"></ng-template>
