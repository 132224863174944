<section class="about background-gradient p-3">
  <div class="container p-lg-3 p-sm-1">
    <div class="row justify-content-center align-items-center" id="home">
      <div class="col-lg-12 p-5 text-center">
        <h2 class="p-3 text-white">
          The mission of Court<span class="text-secondary">Stack</span> is to empower the delivery of digital court
          services in an innovative and industry
          transformative way, that is intelligent, efficient, and cost-effective.
        </h2>
        <h5 class="p-3 text-white">
          "Our goal is to deliver a common
          standards-based platform that will extend add-on solutions to all case management systems in
          an innovative and industry-transformative way"
        </h5>
        <a class="btn btn-gradient courtstack-gradient-btn shadow" routerLink="/docs">View Documentation</a>
      </div>
    </div>
  </div>
</section>
<section class="problem background-grey p-3">
  <div class="container-fluid p-3">
    <div class="row">
      <div class="col-lg col-md-12 col-sm-12 col-xs-12 p-3" id="problem">
        <div class="p-3 card shadow custom-height">
          <div class="card-body">
            <h2 class="text-grey font-weight-bold card-title">Defining the problem</h2>
            <hr>
            <div>
              <p>
                The case management system (CMS) and court applications landscape in California is very
                diverse. It could be considered a microcosm of the challenges that exist nationally when dealing
                with the vast assortment of case management systems and the add-on applications that are
                required to support the modern Digital Court.
                Case-in-point: our current case management environment (both
                in California and nationally), requires that add-on solutions be tightly
                coupled with an underlying case management system, creating a significant deployment
                challenge for best-of-breed systems. Despite considerable efforts across California to build
                innovative solutions in areas like mobile case access, litigant and justice partner case access,
                and eFiling, it has proven extremely difficult to deploy these systems in other Courts. An
                unfortunate dichotomy has resulted: excellent software that has proven very useful to the
                Courts but requires a software development team, a significant amount of time, and a lot of
                money to implement. The investment for most Courts is simply too steep.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg col-md-12 col-sm-12 col-xs-12 p-3">
        <div class="p-3 card shadow custom-height">
          <div class="card-body">
            <h2 class="text-grey font-weight-bold card-title">How will it be solved?</h2>
            <hr>
            <p>
              CourtStack is an active initiative of the California Trial Courts in collaboration with the California Judicial Council that aims to solve this problem by providing a platform and
              framework for building CMS add-on solutions. The goal of CourtStack is to deliver a common
              standards-based platform that will extend add-on solutions to all case management systems in
              an innovative and industry-transformative way. Our CourtStack mission is intentionally broad:
              “To empower the delivery of digital court services in an innovative and industry
              transformative way, that is intelligent, efficient, and cost-effective.”
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="u-divider background-blue-dark down"></div>
<section class="tenants background-blue-dark p-3">
  <div class="container p-3">
    <div class="col-lg-12 p-3 text-center" id="tenent">
      <h1 class=" p-5 text-white">
        Court<span class="text-secondary">Stack</span> is grounded on five core tenets
      </h1>
    </div>
    <div class="p-3 d-lg-block d-none">
      <ul class="timeline">
        <li>
          <div class="timeline-badge bg-secondary">1</div>
          <div class="timeline-panel rounded shadow">
            <div>
              <h4 class="text-grey font-weight-bold">CourtStack will be a reliable, robust, secure digital platform</h4>
            </div>
            <hr>
            <div>
              <p>that is standards compliant with common, reusable components and services that will create a foundation
                for which all CMS add-on solutions can be built in the future.</p>
            </div>
          </div>
        </li>
        <li class="timeline-inverted">
          <div class="timeline-badge bg-secondary">2</div>
          <div class="timeline-panel rounded shadow">
            <div>
              <h4 class="text-grey font-weight-bold">CourtStack will establish a digital ecosystem</h4>
            </div>
            <hr>
            <div>
              <p>that fosters technical innovation in the court community to build, deploy, and support
                CourtStack-compliant products.</p>
            </div>
          </div>
        </li>
        <li id="tenent3">
          <div class="timeline-badge bg-secondary">3</div>
          <div class="timeline-panel rounded shadow">
            <div>
              <h4 class="text-grey font-weight-bold">CourtStack will enable the productization of existing software assets
              </h4>
            </div>
            <hr>
            <div>
              <p>so that other courts can use and benefit from them.</p>
            </div>
          </div>
        </li>
        <li class="timeline-inverted">
          <div class="timeline-badge bg-secondary">4</div>
          <div class="timeline-panel rounded shadow">
            <div>
              <h4 class="text-grey font-weight-bold">CourtStack will establish an easy-to-use, cohesive, and integrated suite of
                products</h4>
            </div>
            <hr>
            <div>
              <p>that advances the Digital Court and is well aligned with court and/or judiciary strategic plans and
                technology initiatives.</p>
            </div>
          </div>
        </li>
        <li>
          <div class="timeline-badge bg-secondary">5</div>
          <div class="timeline-panel rounded shadow">
            <div>
              <h4 class="text-grey font-weight-bold">CourtStack will save courts money</h4>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="p-3 d-lg-none d-block">
    <div class="row">
      <div class="p-3">
        <div class="col-12 text-center p-3">
          <span class="rounded-circle bg-secondary custom-badge">1</span>
        </div>
        <div class="col-12 rounded shadow card p-3">
          <div>
            <h4 class="text-grey font-weight-bold">CourtStack will be a reliable, robust, secure digital platform</h4>
          </div>
          <hr>
          <div>
            <p>that is standards compliant with common, reusable components and services that will create a foundation
              for which all CMS add-on solutions can be built in the future.</p>
          </div>
        </div>
      </div>
      <div class="p-3">
        <div class="col-12 text-center p-3">
          <span class="rounded-circle bg-secondary custom-badge">2</span>
        </div>
        <div class="col-12 rounded shadow card p-3">
          <div>
            <h4 class="text-grey font-weight-bold">CourtStack will establish a digital ecosystem</h4>
          </div>
          <hr>
          <div>
            <p>that fosters technical innovation in the court community to build, deploy, and support
              CourtStack-compliant products.</p>
          </div>
        </div>
      </div>
      <div class="p-3">
        <div class="col-12 text-center p-3">
          <span class="rounded-circle bg-secondary custom-badge">3</span>
        </div>
        <div class="col-12 rounded shadow card p-3">
          <div>
            <h4 class="text-grey font-weight-bold">CourtStack will help to productize the California branch’s software assets
            </h4>
          </div>
          <hr>
          <div>
            <p>so that all of our 58 Trial Courts can use and benefit from them</p>
          </div>
        </div>
      </div>
      <div class="p-3">
        <div class="col-12 text-center p-3">
          <span class="rounded-circle bg-secondary custom-badge">4</span>
        </div>
        <div class="col-12 rounded shadow card p-3">
          <div>
            <h4 class="text-grey font-weight-bold">CourtStack will establish an easy-to-use, cohesive, and integrated suite of
              products</h4>
          </div>
          <hr>
          <div>
            <p>that advances the Digital Court and is well aligned with the California branches’ strategic plan and
              technology initiatives.</p>
          </div>
        </div>
      </div>
      <div class="p-3 timeline-container">
        <div class="col-12 text-center p-3">
          <span class="rounded-circle bg-secondary custom-badge">5</span>
        </div>
        <div class="col-12 rounded shadow card p-3">
          <div>
            <h4 class="text-grey font-weight-bold">CourtStack will save courts money</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="u-divider background-blue-dark up"></div>
<section class="architecture background-grey p-3">
  <div class="container p-3">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 card shadow">
        <div class="card-body">
          <h2 class="text-grey font-weight-bold card-title text-center">
            The CourtStack Application Suite and Services Model
          </h2>
          <h5 class="text-grey card-title text-center">
            What it is and how it works
          </h5>
          <hr>
          <p>
            CourtStack currently is a conceptual suite of applications (for which the California branch has
            existing IP assets, primarily residing with the founding Trial Courts) built around a foundational
            set of CourtStack services that power state applications, local branch applications, and
            integrations between the branch entities and their partners. CourtStack is innovative in its
            development of the “Virtual CMS” which lays the foundation for the platform and ecosystem.
            The “Virtual CMS” is an abstraction layer that sits on top of the branch CMS implementation.
            This abstraction layer allows higher level components and applications to be written against it
            for interaction with the CMS. Applications and components will interact with the Virtual CMS
            rather than the CMS directly. This abstraction will give the applications written for CourtStack
            the freedom to run on any CMS for which there is a Virtual CMS implementation.
          </p>
          <div class="col-lg-12 text-center">
            <img src="assets/diagrams/architecture.png" class="img-fluid p-lg-5 p-md-3 p-sm-1"
              alt="Responsive image">
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="vcms background-grey p-3">
  <div class="container p-3">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 card shadow">
        <div class="card-body">
          <h2 class="text-grey font-weight-bold card-title text-center">
            Components of the Virtual CMS
          </h2>
          <hr>
          <div class="row">
            <div class="col-lg-6 p-4">
              <img src="assets/diagrams/virtual-cms.png" class="img-fluid" alt="Responsive image">
            </div>
            <div class="col-lg-6 p-4">
              <p>
                The Virtual CMS is comprised of 5 main components, an object model, data binding component, document
                binding component, REST API and GraphQL API. The data binding component is a pluggable component that is
                CMS dependent and interchangeable based on the CMS implementation that is targeted. The data binding
                component is where the necessary data mapping and payload transformation is performed to build a
                consistent reliable standardized API for case management systems. The data binding component interacts
                with the proprietary CMS API and data model to transform the proprietary CMS payloads into a
                standardized payload that can be processed and consumed through the CourtStack Virtual CMS API. The
                document binding component works similar to the data binding component, but simply focuses on document
                management systems. This flexible partitioning creates an environment where the Virtual CMS can easily
                be configured to support case management systems with integrated document management and case management
                systems that rely on external independent document management systems.
              </p>
              <a class="btn btn-gradient courtstack-gradient-btn shadow" routerLink="/docs">View Documentation</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="u-background-white">
  <div class="u-divider background-grey up"></div>
</div>
<section class="framework background-white p-3">
  <div class="container p-3">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 card shadow" id="framework">
        <div class="card-body">
          <h2 class="text-grey font-weight-bold card-title text-center">
            CourtStack is Aligned with the NCSC Court Technology Framework
          </h2>
          <hr>
          <div class="row">
            <div class="col-lg-6 p-4">
              <p>
                CourtStack is aligned with and can
                be viewed as an implementation of
                the National Center for State Courts
                (NCSC) Court Technology Framework
                for applications and the NCSC JTC
                Next Generation Application
                Component Model. CourtStack will
                help foster an environment where
                future development of CMS add-on
                solutions are built on technical and
                architectural industry standards.
              </p>
              <a class="btn btn-gradient courtstack-gradient-btn shadow"
                href="https://www.ncsc.org/Services-and-Experts/Technology-tools/Court-Technology-Framework.aspx"
                target="_blank">Learn More <i class="fa fa-external-link-alt"></i>
              </a>
            </div>
            <div class="col-lg-6 p-4">
              <img src="assets/diagrams/ncsc.png" class="img-fluid" alt="Responsive image">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="governance background-white p-3">
  <div class="container p-3">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-3 mb-5 card shadow">
        <div class="card-body">
          <h2 class="text-grey font-weight-bold card-title text-center">
            CourtStack Governance
          </h2>
          <hr>
          <p>
            CourtStack is governed by its founding Trial Courts: Los Angeles (Chair/Lead), Orange, Santa Clara and Monterey, in collaboration with the Judicial Council of California Information Technology Division (JCIT).
             It is being implemented by the CourtStack Project Team and the community driven CourtStack Architecture Group. The CourtStack Architecture Group is currently made up of Senior Developers, Software Architects, and Chief Information Officers from various Trial Courts.
          </p>
          <a class="btn btn-gradient courtstack-gradient-btn shadow" routerLink="/team">Meet the Team</a>
        </div>
      </div>
    </div>
  </div>
</section>
