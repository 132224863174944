<section class="global-footer">
  <div class="container p-5">
    <div class="row">
      <div class="col-lg col-md-12 col-sm-12 col-xs-12 mt-4 mb-2">
        <div class="">
          <img src="assets/CourtStack-Light.svg" class="courtstack-logo-sm" alt="CourtStack">
        </div>
        <div class="mt-3">
          <a (click)="sendEmail()" class="footer-link"><i class="fa fa-envelope"></i> CourtStack@lacourt.org</a>
          <a class="footer-link" target="_blank" href="https://mailchi.mp/25599b176f78/courtstacknotifications"><i class="fab fa-mailchimp"></i> Subscribe for updates</a>
          <a href="../../publications/courtmanager/2019/aboutcourtstack.pdf" class="footer-link" target="_blank"><i class="fas fa-book"></i> Read the white paper</a>
        </div>
      </div>
      <div class="col-lg col-md-12 col-sm-12 col-xs-12 mt-4 mb-2">
        <h5 class="footer-header">Quick Links</h5>
        <div class="mt-3">
          <a routerLink="/home" class="footer-link">Home</a>
          <a routerLink="/about" class="footer-link">About</a>
          <a routerLink="/release" class="footer-link">Releases</a>
          <a routerLink="/team" class="footer-link">Team</a>
          <a routerLink="/media" class="footer-link">Media</a>
        </div>
      </div>
      <div class="col-lg col-md-12 col-sm-12 col-xs-12 mt-4 mb-2">
        <h5 class="footer-header">Solutions</h5>
        <div class="mt-3">
          <a *ngFor="let solution of solutions" routerLink="/solutions" fragment="{{solution.Id}}" class="footer-link">{{solution.Type}}</a>
        </div>
      </div>
      <div class="col-lg col-md-12 col-sm-12 col-xs-12 mt-4 mb-2">
        <a href="https://www.techwire.net/news/these-state-it-projects-win-honors-as-best-of-california.html"><img src="assets/award.svg" alt="" class="award-seal-sm"></a>
      </div>
      <div class="col-lg-12">
        <hr class="light-divider">
        <span class="copyright">CourtStack &#169; {{year}}</span>
      </div>
    </div>
  </div>
</section>
<a #emailLink class="disabled d-none"></a>
