import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from "@angular/router";


@Injectable({ providedIn: 'root' })
export class RedirectGuard implements CanActivate {
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const externalUrl = route.data['redirectUrl'];
        const target = route.data['target'] ?? '_self';
        window.open(externalUrl, target);
        return true;
    }
}