import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ReleaseService {

  constructor(public http: HttpClient) { }

  getRelease() {
    return this.http.get('assets/release.json').pipe(
      map((releaseConfig: any) => releaseConfig.release.filter((release: any) => release.active))
    );
  }

  getGroups() {
    return this.http.get('assets/release.json').pipe(
      map((releaseConfig: any) => releaseConfig.groups)
    );
  }

  filterReleaseByGroup($release, group) {
    return group ?
      $release.pipe(
        map(
          (releases: any) => releases
            .filter(release => release.groups.indexOf(group) !== -1)
            .sort((m1, m2) => m1.name < m2.name ? -1 : 1))
      ) :
      $release.pipe(
        map(
          (releases: any) => releases
           
        )
      );
  }
}
