import { Component, OnInit, ElementRef } from '@angular/core';
import { openClose } from '../common/animations/openClose';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css'],
  animations: [openClose]
})
export class AboutComponent implements OnInit {

  pageStops = ['home', 'problem', 'tenent', 'architecture', 'framework'];
  currentStop = 0;
  show = false;
  apiVersion = environment.apiVersion;


  constructor() { }

  ngOnInit() {}

  toggleAnimationState() {
    this.show = !this.show;
  }

  viewDocument() {
    window.open('assets/publications/courtmanager/2019/aboutcourtstack.pdf');
  }
}
