import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {

  constructor() { }

  @ViewChild('emailLink') emailLink: ElementRef;

  ngOnInit() {
  }

  sendEmail() {
    this.emailLink.nativeElement.setAttribute('href', 'mailto:courtstack@lacourt.org');
    this.emailLink.nativeElement.click();
    this.emailLink.nativeElement.remove();
  }

}
