<section class="background-gradient">
  <div class="container p-4">
    <div class="row">
      <div class="col-lg-12">
        <h2 class="text-white text-center p-4">Meet the Team</h2>
        <h5 class="text-white text-center p-2">
          Court<span class="text-secondary">Stack</span> is being implemented by its Architecture Group which is
          currently made up of Senior Developers, Software Architects, and Chief Information Officers from its founding
          Courts.
        </h5>
      </div>
    </div>
  </div>
</section>
<section class="team">
  <div class="container-fluid p-5">
    <div class="row">
      <div class="col-lg-12">
        <ul class="nav nav-pills flex-column flex-sm-row align-items-start" id="myTab" role="tablist">
          <li class="nav-item">
            <a class="nav-link link active" data-toggle="tab" role="button" (click)="filterTeam(null)" aria-controls="all" aria-selected="true">All</a>
          </li>
          <li class="nav-item" *ngFor="let group of $groups | async">
            <a class="nav-link link" data-toggle="tab" role="button" (click)="filterTeam(group)" aria-controls="group" aria-selected="false">{{group}}</a>
          </li>
        </ul>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 p-3" *ngFor="let member of $filteredMembers | async">
        <div class="card shadow p-2">
          <div class="card-body">
            <img *ngIf="member.img !== null" src="{{member.img}}" class="member-container member-contents">
            <span *ngIf="member.img === null" class="member-container member-contents member-initials">
              <span>{{member.initials}}</span>
            </span>
            <div class="text-center pt-2">
              <h4 class="member-name mb-0">{{member.name}}</h4>
              <h5 class="mb-0" *ngIf="member.title">{{member.title}}</h5>
              <p>
                {{member.employer}}
              </p>
              <a class="card-link text-secondary link" [ngClass]="{'hide': member.bio === null}" data-toggle="modal" data-target="#bio"
                (click)="selectMember(member)">Read Bio</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- User Biography Modal -->
<div class="modal fade" id="bio" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header border-bottom-0">
        <button type="button" class="close close-custom" data-dismiss="modal" aria-label="Close">
          <i class="fa fa-times"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
          <div class="col-lg-12 p-2">
            <img *ngIf="selectedMember?.img !== null" src="{{selectedMember.img}}"
              class="member-container member-contents">
          </div>
          <div class="col-lg-12 p-1">
            <span *ngIf="selectedMember?.img === null" class="member-container member-contents member-initials">
              <span>{{selectedMember?.initials}}</span>
            </span>
            <div class="text-center p-2">
              <h4 class="member-name mb-0">{{selectedMember?.name}}</h4>
              <h5 class="mb-0" *ngIf="selectedMember?.title !== null">{{selectedMember?.title}}</h5>
              <p>
                {{selectedMember?.employer}}
              </p>
            </div>
          </div>
          <div class="col-lg-12 p-3">
            <p style="white-space: pre-line;">{{selectedMember?.bio}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
