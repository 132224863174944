import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class MediaService {

    constructor(public http: HttpClient) { }

    getMedia() {
        return this.http.get('assets/media.json').pipe(
            map((mediaConfig: any) => mediaConfig.media.filter((media: any) => media.active)
            .sort((media1: any, media2: any) => (media1.new === media2.new) ? 0 : media1.new ? -1 : 1 ))
        );
    }

    getGroups() {
        return this.http.get('assets/media.json').pipe(
            map((mediaConfig: any) => mediaConfig.groups)
        );
    }

    filterMediaByGroup($media, group) {
        return group ?
            $media.pipe(
                map(
                    (medias: any) => medias
                        .filter(media => media.groups.indexOf(group) !== -1)
                )
            ) :
            $media.pipe(
                map(
                    (medias: any) => medias
                )
            );
    }

}
