<section class="background-gradient">
    <div class="container p-4">
        <div class="row">
            <div class="col-lg-12">
                <h2 class="text-white text-center p-4">Learn CourtStack</h2>
            </div>
        </div>
    </div>
</section>
<section class="learn">
    <div class="container-fluid p-5">
        <div class="row">
            <div class="col-lg-12">
                <ul class="nav nav-pills flex-column flex-sm-row align-items-start" id="myTab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link link active" data-toggle="tab" role="button" (click)="filterClasses(null)"
                            aria-controls="all" aria-selected="true">All</a>
                    </li>
                    <li class="nav-item" *ngFor="let group of $groups | async">
                        <a class="nav-link link" data-toggle="tab" role="button" (click)="filterClasses(group)"
                            aria-controls="group" aria-selected="false">{{group}}</a>
                    </li>
                </ul>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 p-3"
                *ngFor="let class of $filteredClasses | async">
                <div class="card shadow">
                    <div class="coverimg-container relative text-center" [style.backgroundImage]="getUrl(class)">
                        <div class="coverimg-overlay overlay-position"></div>
                        <img [src]="class.coverImg" class="relative" width="80%">
                    </div>
                    <div class="card-body h-100">
                        <span class="badge badge-secondary card-badge" *ngIf="class.new">New</span>
                        <h5 class="card-title">{{class.title}}</h5>
                        <p class="card-text text-grey">{{class.subtitle}}</p>
                        <a class="btn btn-sm btn-tertiary rounded mr-2 shadow" data-toggle="modal" data-target="#bio" (click)="selectClass(class)">Find out more</a>
                        <button class="btn btn-sm btn-outline-tertiary rounded mr-2 shadow" data-toggle="modal" data-target="#register">Register for Workshop</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="modal fade" id="bio" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header border-bottom-0">
          <button type="button" class="close close-custom" data-dismiss="modal" aria-label="Close">
            <i class="fa fa-times"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="container-fluid">
            <div class="col-lg-12 p-1">
                <div #description></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--This is not dynamic off of JSON. This is a quick fix to get the content out for CTC. Standardize class structure and make dynamic-->
  <div class="modal fade" id="register" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header border-bottom-0">
          <button type="button" class="close close-custom" data-dismiss="modal" aria-label="Close">
            <i class="fa fa-times"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="container-fluid">
            <div class="col-lg-12 p-1">
                <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Course Name</th>
                        <th scope="col">Presenters</th>
                        <th scope="col">Date/Time</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>Developing standards-based applications with the Virtual CMS (A Court Component Model Develoment Workshop)</th>
                        <td><b>Dylan Demmers</b> - Senior Software Engineer<br><br><b>Glen Souza</b> - Enterprise Architect</td>
                        <td>Monday 9/20 9:00 am – 1:00 pm (Eastern 12:00 – 4:00 pm)</td>
                        <td><a class="btn btn-sm btn-tertiary rounded shadow" target="_blank" href="https://online.ncsconline.org/ncscssa/f?p=EVTSSA:4010::::4010:P0_EVENT_ID:3602">Register</a></td>
                      </tr>
                      <tr>
                        <th>Developing standards-based applications with the Virtual CMS (A Court Component Model Develoment Workshop)</th>
                        <td><b>Dylan Demmers</b> - Senior Software Engineer<br><br><b>Glen Souza</b> - Enterprise Architect</td>
                        <td>Tuesday 9/21 9:00 am – 1:00 pm (Eastern 12:00 – 4:00 pm)</td>
                        <td><a class="btn btn-sm btn-tertiary rounded shadow" target="_blank" href="https://online.ncsconline.org/ncscssa/f?p=EVTSSA:4010::::4010:P0_EVENT_ID:3605">Register</a></td>
                      </tr>
                    </tbody>
                  </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>