import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DocumentationService {

  constructor(private http: HttpClient) { }

  public loadApiSpec(path) {
    return this.http.get(path);
  }

  public getApiSpecs() {
    return this.http.get('assets/documentation.json');
  }
  public getMatrixSpecs(version) {
    return this.http.get(`assets/api-specs/matrix/${version}.json`);
  }
}
