<nav class="navbar navbar-expand-md navbar-light globalNav elev-4">
  <a class="navbar-brand" routerLink="/home">
    <img src="assets/CourtStack.svg" class="d-inline-block align-top courtstack-brand" alt="">
  </a>

  <button class="navbar-toggler text-primary" type="button" data-toggle="collapse" data-target="#navbarCollapse"
    aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse align-content-end" id="navbarCollapse">
    <ul class="navbar-nav ml-auto">
      <li class="nav-item mr-2" routerLinkActive="active">
        <a class="nav-link" routerLink="/about">
          About
        </a>
      </li>
      <li class="nav-item mr-2" routerLinkActive="active">
        <a class="nav-link" routerLink="/solutions">
          Solutions
        </a>
      </li>
      <li class="nav-item mr-2" routerLinkActive="active">
        <a class="nav-link" routerLink="/release">
          Releases
        </a>
      </li>
      <li class="nav-item mr-2" routerLinkActive="active">
        <a class="nav-link" routerLink="/installation">
          Download
        </a>
      </li>
      <li class="nav-item mr-2" routerLinkActive="active">
        <a class="nav-link" routerLink="/team">
          Meet the Team
        </a>
      </li>
      <li class="nav-item mr-2" routerLinkActive="active">
        <a class="nav-link" routerLink="/media">
          Media
        </a>
      </li>
      <li class="nav-item mr-2" routerLinkActive="active" *ngIf="false">
        <a class="nav-link" routerLink="/learn">
          Learn
        </a>
      </li>
      <li class="nav-item mr-2">
        <a class="nav-link" target="_blank" href="https://mailchi.mp/25599b176f78/courtstacknotifications">
          Subscribe
        </a>
      </li>
    </ul>
    <span>


      <button class="btn btn-sm btn-outline-tertiary rounded mr-2 d-none"><i class="fab fa-slack"></i>
        Community</button>
      <a class="btn btn-sm btn-outline-tertiary rounded mr-2" routerLink="/docs"><i class="fas fa-code"></i>
        Documentation</a>
        <div ngbDropdown class="d-inline-block">
          <button class="btn btn-sm btn-outline-tertiary rounded mr-2" id="dropdownBasic1" ngbDropdownToggle><i class="far fa-comment-alt"></i> Provide Feedback</button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <a target="_blank" style="font-size: 0.9em;color:gray;" ngbDropdownItem href="https://forms.office.com/g/BaMp8qbPLb">Feature Request</a>
            <a target="_blank" style="font-size: 0.9em;color:gray;" ngbDropdownItem href="https://forms.office.com/g/nS98tNXWj4">API/Platform Feedback</a>
          </div>
        </div>
      <button class="btn btn-sm btn-outline-tertiary rounded mr-2" (click)="sendEmail()"><i class="fa fa-envelope"></i>
        Contact Us</button>
    </span>
  </div>
  <a #emailLink class="disabled d-none"></a>
</nav>