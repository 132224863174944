import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from '../environments/environment';


declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'CourtStack';
  constructor(public router: Router, ) { }

  ngOnInit() {
    if (environment.production) {
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.analyticsId;
      document.head.prepend(script);﻿
      this.router.events.subscribe(event => {
        console.log('router-event', event);
        if (event instanceof NavigationEnd) {
          console.log(environment.analyticsId);
          gtag('config', environment.analyticsId,
            {
              page_path: event.urlAfterRedirects
            }
          );
        }
      });
    }
  }
}
