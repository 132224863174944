<section class="background-gradient">
    <div class="container p-4">
        <div class="row">
            <div class="col-lg-12">
                <h2 class="p-4 text-white text-center">
                    vCMS Installation
                </h2>
            </div>
        </div>
    </div>
</section>
<section class="installation">
    <div class="container-fluid p-5">
        <div class="col-lg-12">
            <div class="download-link-container my-5 d-flex justify-content-center">
                <a class="btn btn-gradient courtstack-gradient-btn shadow" href="https://bit.ly/3NRui7s">Click to download vCMS installer</a>
            </div>
            <div class="d-flex justify-content-center">
                <div class="instructions-container">
                    <div class="step">
                        <div class="v-stepper">
                            <div class="circle">
                                <span>1</span>
                            </div>
                            <div class="line"></div>
                        </div>
                        <div class="content">
                            Click the line above to download the vCMS installer.
                        </div>
                    </div>
                    <div class="step">
                        <div class="v-stepper">
                            <div class="circle">
                                <span>2</span>
                            </div>
                            <div class="line"></div>
                        </div>
                        <div class="content">
                            Run the downloaded installer, preferrably with administrator rights.
                        </div>
                    </div>
                    <div class="step final-step">
                        <div class="v-stepper">
                            <div class="circle">
                                <span>3</span>
                            </div>
                            <div class="line"></div>
                        </div>
                        <div class="content">
                            Follow the onscreen prompts to complete the installation.
                        </div>
                    </div>
                    <div class="final">
                        A detailed guide on how to install vCMS can be found here <a
                            href="https://courtstack.org/CourtStack-Docs/#/automated-installer-usage-guide">vCMS
                            Installation Guide</a>.
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>