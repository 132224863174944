import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentationService } from '../documentation/documentation.service';

declare var Redoc: any;

@Component({
  selector: 'app-documentation-viewer',
  templateUrl: './documentation-viewer.component.html',
  styleUrls: ['./documentation-viewer.component.css']
})
export class DocumentationViewerComponent implements OnInit {

  options = redocOptions;

  constructor(public activatedRoute: ActivatedRoute, public documentationService: DocumentationService, public elementRef: ElementRef, public router: Router) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(x => {
      this.getApiSpec(decodeURI(x.application), decodeURI(x.version));
    });
  }

  loadDocumentation(path) {
    this.documentationService.loadApiSpec(path).subscribe((x: any) => {
      this.initRedoc(x);
    }, err => {

    });
  }

  getApiSpec(app, version) {
    this.documentationService.getApiSpecs().subscribe((docs: any) => {
      const docVersion = docs.Documentation.Apps.find(x => x.Name === app).Versions.find(x => x.Version === version);
      this.loadDocumentation(docVersion.Path);
    });
  }

  initRedoc(spec) {
    const documentationRef = this.elementRef.nativeElement.querySelector('#documentation');
    Redoc.init(spec, this.options, documentationRef, (callback) => { });
  }

}

const redocOptions = {
  pathInMiddlePanel: true,
  hideHostname: true,
  hideLoading: true,
  theme: {
    menu: {
      backgroundColor: '#ffffff'
    },
    rightPanel: {
      backgroundColor: '#040d15',
      width: '30%'
    },
    typography: {
      links: {
        color: '#3173B5'
      }
    },
    colors: {
      primary: {
        main: '#3173B5'
      }
    }
  }
};
