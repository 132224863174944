<section class="background-gradient p-4">
    <div class="container">
        <div class="row justify-content-center align-items-center" id="home">
            <div class="col-lg-12 text-center">
                <h2 class="p-4 text-white">
                    Documentation
                </h2>
            </div>
        </div>
    </div>
</section>
<section class="background-white">
    <div class="container-fluid p-5">
        <div class="row">
            <div class="col-lg-12">
                <ul class="nav nav-pills flex-column flex-sm-row align-items-start">
                    <li class="nav-item">
                        <a class="nav-link link active" href="#apis" data-toggle="tab">APIs</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link link" href="https://courtstack.org/CourtStack-Docs/#/summary-standard-model" target="_blank">vCMS Overview</a>
                    </li>
                    <li class="nav-item" *ngIf="false">
                        <a class="nav-link link" href="#integration" data-toggle="tab">Integration Components</a>
                    </li>
                    <li class="nav-item" *ngIf="false">
                        <a class="nav-link link" href="#platform" data-toggle="tab">Platform Components</a>
                    </li>
                    <li class="nav-item" *ngIf="false">
                        <a class="nav-link link" href="#applications" data-toggle="tab">Applications</a>
                    </li>
                </ul>
                <div class="tab-content">
                    <div class="tab-pane active" id="apis">
                        <div class="row p-4 justify-content-center align-items-center">
                            <div class="col-12 text-center">
                                <h3 class="m-4 font-weight-bolder">
                                    Choose an API to start building today
                                </h3>
                            </div>
                            <div class="col-lg-6 col-sm-12">
                                <select (change)="selectChange($event)"  [formControl]="selectedApplication" class="docs-selector custom-select custom-select-lg">
                  <option [value]="null">Choose An API</option>
                  <optgroup *ngFor="let app of documentation['Apps']" [label]="app.Name">
                    <ng-container *ngFor="let application of app.Versions">
                        <option *ngIf="application.Display == true" [ngValue]="application">{{application.Version}}</option>
                    </ng-container>
                    
                  </optgroup>
                </select>
                            </div>
                        </div>
                        <div *ngIf="version != null">
                            <div class="row p-4 justify-content-center align-items-center">
                                <div class="col-lg-6 col-sm-12">
                                    <h4 class="">Version {{version.Version}}</h4>
                                </div>
                                <div class="w-100"></div>
                                <div class="col-lg-6 col-sm-12">
                                    <a style="width: 100%;" class="btn btn-outline-tertiary rounded mr-2" (click)="openDocs()">
                                        <span style="float: left;" class="mr-auto  font-weight-bolder">
                                        View documentation for {{version.Version}}
                                        </span>
                                        <span style="float: right;">
                                            <span class="badge badge-pill ml-1 text-white justify-content-end align-items-center lh-clear" *ngFor="let tag of version.Tags" style="line-height: none !important;" [style.background-color]="tag.Color">{{tag.Tag}}</span>
                                            <span class="ml-3 justify-content-end align-items-center lh-clear">
                                                <i class="fa fa-external-link-alt "></i>
                                            </span>
                                        </span>

                                    </a>
                                </div>
                            </div>
                            <br/>
                            <br/>
                            <div *ngIf="this.name == 'Virtual CMS'" id="matrix">
                                <div class="row px-4 justify-content-center align-items-center">
                                    <div class="col-lg-6 col-sm-12">
                                        <h4 class="">Virtual CMS API Features</h4>

                                        <h6 class="bg-light p-2">Features Legend</h6>
                                        <ul class="list-group list-group-light">
                                            <li class="list-group-item border-0"><i class="docs-check fas fa-check"></i>  Implemented</li>
                                            <li class="list-group-item border-0"><i class="docs-times fas fa-times"></i>  Not Implemented</li>
                                            <li class="list-group-item border-0"><b class="docs-hyphen" >-</b> Not Supported by the CMS</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="row p-4 justify-content-center align-items-center">
                                    <div class="col-lg-6 col-sm-12">
                                        <div class="table-responsive">
                                            <table class="table table-bordered">
                                                <thead>
                                                   <tr>
                                                        <th colspan="2" scope="col" style="border-top: none;border-left:none;"></th>
                                                        <th colspan="2" scope="col" class="text-center">Case Management System </th>
                                                        <th colspan="1" scope="col" class="text-center">SDK</th>
                                                    </tr>
                                                    <tr>
                                                        <th colspan="2">Features</th>
                                                        <th scope="col">
                                                            <div class="text-center">ECourt</div>
                                                        </th>
                                                        <th scope="col">
                                                            <div class="text-center">Odyssey</div>
                                                        </th>
                                                        <th scope="col">
                                                            <div class="text-center">Redline</div>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <ng-container *ngFor="let feature of matrix['Features']">
                                                        <tr style="background-color: #eee;">
                                                            <td colspan="5"><b>{{feature.Name}}</b></td>
                                                        </tr>
                                                        <tr *ngFor="let method of feature['Methods']">
                                                            <td colspan="2">{{method.Name}}</td>
                                                            <td  scope="col" class="text-center">
                                                                <i *ngIf="method.ECourt == true" class="docs-check fas fa-check"></i>
                                                                <i *ngIf="method.ECourt == false" class="docs-times fas fa-times"></i>
                                                                <b class="docs-hyphen" *ngIf="method.ECourt == null">-</b>
                                                            </td>
                                                            <td  scope="col" class="text-center">
                                                                <i *ngIf="method.Odyssey == true" class="docs-check fas fa-check"></i>
                                                                <i *ngIf="method.Odyssey == false" class="docs-times fas fa-times"></i>
                                                                <b class="docs-hyphen" *ngIf="method.Odyssey == null">-</b>
                                                            </td>
                                                            <td  scope="col" class="text-center">
                                                                <i *ngIf="method.RedLine == true" class="docs-check fas fa-check"></i>
                                                                <i *ngIf="method.RedLine == false" class="docs-times fas fa-times"></i>
                                                                <b class="docs-hyphen" *ngIf="method.RedLine == null">-</b>
                                                            </td>
                                                        </tr>
                                                    </ng-container>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>
                    <div class="tab-pane" id="integration">
                        <div class="row p-4 justify-content-center align-items-center">
                            <div class="col-12 text-center">
                                <div class="col-12 text-center">
                                    <h6 class="m-4">
                                        Documentation under construction...
                                    </h6>
                                    <h6 class="font-weight-bolder">Subscribe to stay informed on the latest developments</h6>
                                </div>
                                <div class="col-12 text-center">
                                    <a class="btn btn-gradient courtstack-gradient-btn mt-2 shadow" target="_blank" href="https://mailchi.mp/25599b176f78/courtstacknotifications">Subscribe</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane" id="platform">
                        <div class="row p-4 justify-content-center align-items-center">
                            <div class="col-12 text-center">
                                <h6 class="m-4">
                                    Documentation under construction...
                                </h6>
                                <h6 class="font-weight-bolder">Subscribe to stay informed on the latest developments</h6>
                            </div>
                            <div class="col-12 text-center">
                                <a class="btn btn-gradient courtstack-gradient-btn mt-2 shadow" target="_blank" href="https://mailchi.mp/25599b176f78/courtstacknotifications">Subscribe</a>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane" id="applications">
                        <div class="row p-4">
                            <div class="col-12 text-center">
                                <div class="col-12 text-center">
                                    <h6 class="m-4">
                                        Documentation under construction...
                                    </h6>
                                    <h6 class="font-weight-bolder">Subscribe to stay informed on the latest developments</h6>
                                </div>
                                <div class="col-12 text-center">
                                    <a class="btn btn-gradient courtstack-gradient-btn mt-2 shadow" target="_blank" href="https://mailchi.mp/25599b176f78/courtstacknotifications">Subscribe</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="background-grey">
    <div class="container p-4">
        <div class="row p-4">
            <div class="col-12">
                <h3>To aquire the installable components, contact the CourtStack team</h3>
            </div>
            <div class="col-12">
                <button class="btn btn-gradient courtstack-gradient-btn mt-2 shadow" (click)="sendEmail()">Contact Us</button>
            </div>
        </div>
    </div>
</section>
<a #emailLink class="disabled d-none"></a>