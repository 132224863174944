import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ReleaseComponent } from './release/release.component';
import { SolutionsComponent } from './solutions/solutions.component';
import { TeamComponent } from './team/team.component';
import { MediaComponent } from './media/media.component';
import { DocumentationComponent } from './documentation/documentation.component';
import { DocumentationViewerComponent } from './documentation-viewer/documentation-viewer.component';
import { LearnComponent } from './learn/learn.component';
import { InstallationComponent } from './installation/installation.component';
import { RedirectComponent } from './redirect/redirect.component';
import { RedirectGuard } from './redirect/redirect.guard';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'release', component: ReleaseComponent },
  { path: 'solutions', component: SolutionsComponent },
  { path: 'team', component: TeamComponent },
  { path: 'learn', component: LearnComponent },
  { path: 'media', component: MediaComponent },
  { path: 'docs', component: DocumentationComponent },
  { path: 'doc-viewer/:application/:version', component: DocumentationViewerComponent },
  { path: 'installation', component: InstallationComponent },
  {
    path: 'subscribe', component: RedirectComponent,
    data: { redirectUrl: 'https://mailchi.mp/25599b176f78/courtstacknotifications', target: '_self' },
    canActivate: [RedirectGuard]
  },
  { path: '**', redirectTo: '/home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
