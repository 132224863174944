import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { SolutionsService } from './solutions.service';

@Component({
  selector: 'app-solutions',
  templateUrl: './solutions.component.html',
  styleUrls: ['./solutions.component.css']
})
export class SolutionsComponent implements OnInit, AfterViewInit {

  @ViewChild('emailLink') emailLink: ElementRef;

  public solutionGroups = [];
  public fragment;

  constructor(public solutionsService: SolutionsService, public route: ActivatedRoute) { }

  ngOnInit() {
    this.getSolutions();
    this.route.fragment.subscribe(fragment => { this.fragment = fragment; });
  }

  ngAfterViewInit(): void {
      setTimeout(() => {
        const elem = document.querySelector('#' + this.fragment);
        if(elem) {
          elem.scrollIntoView({behavior: 'auto'});
        }
      }, 100);
  }

  sendEmail() {
    this.emailLink.nativeElement.setAttribute('href', 'mailto:courtstack@lacourt.org');
    this.emailLink.nativeElement.click();
    this.emailLink.nativeElement.remove();
  }

  getSolutions() {
    this.solutionsService.getSolutions().subscribe((res: any) => {
      this.solutionGroups = res;
    });
  }
}
