import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SolutionsService } from '../solutions/solutions.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  public year;

  constructor(public solutionsService: SolutionsService) { }

  @ViewChild('emailLink') emailLink: ElementRef;
  public solutions = [];

  ngOnInit() {
    this.getSolutions();
    this.year = new Date().getFullYear();
  }

  sendEmail() {
    this.emailLink.nativeElement.setAttribute('href', 'mailto:courtstack@lacourt.org');
    this.emailLink.nativeElement.click();
    this.emailLink.nativeElement.remove();
  }

  getSolutions() {
    this.solutionsService.getSolutions().subscribe((res: any) => {
      this.solutions = res;
    });
  }

}
