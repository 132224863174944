import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MediaService } from './media.service';
import { PublicationType } from './publicationType';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.css']
})
export class MediaComponent implements OnInit {

  @ViewChild('video') videoTemplate: ElementRef;
  @ViewChild('audio') audioTemplate: ElementRef;
  @ViewChild('document') documentTemplate: ElementRef;
  @ViewChild('link') linkTemplate: ElementRef;
  @ViewChild('embeded') embededTemplate: ElementRef;



  $media: Observable<any>;
  $groups: Observable<any>;
  $filteredMedia: Observable<any>;

  constructor(private mediaService: MediaService, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.getMedia();
    this.getGroups();
  }

  getMedia() {
    this.$media = this.mediaService.getMedia();
    this.filterMedia(null);
  }

  getGroups() {
    this.$groups = this.mediaService.getGroups();
  }

  filterMedia(group) {
    this.$filteredMedia = this.mediaService.filterMediaByGroup(this.$media, group);
  }

  sanitizeUrl(url: string) {
    const sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    return sanitizedUrl;
  }

  setTemplateReference(publicationType) {
    switch (publicationType) {
      case PublicationType[0]:
        return this.videoTemplate;
      case PublicationType[1]:
        return this.audioTemplate;
      case PublicationType[2]:
        return this.documentTemplate;
      case PublicationType[3]:
        return this.linkTemplate;
      case PublicationType[4]:
        return this.embededTemplate;
    }
  }

}
