<section class="background-gradient">
  <div class="container p-4">
    <div class="row">
      <div class="col-lg-12">
        <h2 class="text-white text-center p-4">Solutions</h2>
      </div>
    </div>
  </div>
</section>
<section #solutionDiv *ngFor="let solutionGroup of solutionGroups" class="solution" id="{{solutionGroup.Id}}">
  <div class="container p-3">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 card shadow solution-container">
        <div class="card-body">
          <h2 class="text-grey font-weight-bold card-title text-center">
            {{solutionGroup.Type}}
          </h2>
          <hr>
          <div class="row">
            <div class="col-lg-5 p-4" *ngIf="solutionGroup.Img">
              <img src="{{solutionGroup.Img}}" class="img-fluid" alt="Responsive image">
            </div>
            <div class="p-4" [ngClass]="{'col-lg-10': !solutionGroup.Img, 'col-lg-7 ': solutionGroup.Img}">
              <div *ngFor="let solution of solutionGroup.Solutions">
                <p>
                  <b>{{solution.Name}}</b> - Latest Release: <b>{{solution.LatestRelease}}</b> Date: <b>{{solution.ReleaseDate}}</b><br />
                </p>
              </div>
              <br>
              <div>
                <p class="information">For more information or to acquire the installable component</p>
                <button class="btn btn-gradient btn-sm courtstack-gradient-btn shadow rounded mr-2" (click)="sendEmail()"><i class="fa fa-envelope"></i> Contact Us</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<a #emailLink class="disabled d-none"></a>
