import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ReleaseService } from './release.service';



@Component({
  selector: 'app-release',
  templateUrl: './release.component.html',
  styleUrls: ['./release.component.css']
})
export class ReleaseComponent implements OnInit {
  $release: Observable<any>;
  $filteredRelease: Observable<any>;
  $groups: Observable<any>;
  selectedRelease: any;

  constructor(public releaseService: ReleaseService) { }

  ngOnInit() {
    this.getRelease();
    this.getGroups();
  }

  getRelease() {
    this.$release = this.releaseService.getRelease();
    this.filterRelease(null);
  }

  getGroups() {
    this.$groups = this.releaseService.getGroups();
  }

  filterRelease(group) {
    this.$filteredRelease = this.releaseService.filterReleaseByGroup(this.$release, group);
  }

  selectMember(release) {
    this.selectedRelease = release;
  }

}
