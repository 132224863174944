import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { TeamService } from './team.service';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css']
})
export class TeamComponent implements OnInit {
  $members: Observable<any>;
  $filteredMembers: Observable<any>;
  $groups: Observable<any>;
  selectedMember: any;

  constructor(public teamService: TeamService) { }

  ngOnInit() {
    this.getTeam();
    this.getGroups();
  }

  getTeam() {
    this.$members = this.teamService.getMembers();
    this.filterTeam(null);
  }

  getGroups() {
    this.$groups = this.teamService.getGroups();
  }

  filterTeam(group) {
    this.$filteredMembers = this.teamService.filterTeamByGroup(this.$members, group);
  }

  selectMember(member) {
    this.selectedMember = member;
  }

}
