import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class TeamService {

  constructor(public http: HttpClient) { }

  getMembers() {
    return this.http.get('assets/team.json').pipe(
      map((teamConfig: any) => teamConfig.members.filter((member: any) => member.active))
    );
  }

  getGroups() {
    return this.http.get('assets/team.json').pipe(
      map((teamConfig: any) => teamConfig.groups)
    );
  }

  filterTeamByGroup($team, group) {
    return group ?
      $team.pipe(
        map(
          (members: any) => members
            .filter(member => member.groups.indexOf(group) !== -1)
            .sort((m1, m2) => m1.name < m2.name ? -1 : 1))
      ) :
      $team.pipe(
        map(
          (members: any) => members
            .sort((m1, m2) => m1.name < m2.name ? -1 : 1)
        )
      );
  }
}
