import { Component, ElementRef, Inject, OnInit, Optional, SystemJsNgModuleLoader, ViewChild, ɵbypassSanitizationTrustResourceUrl } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { DocumentationService } from './documentation.service';
import { APP_BASE_HREF } from '@angular/common';

@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.css']
})
export class DocumentationComponent implements OnInit {

  constructor(private documentationService: DocumentationService, public router: Router, @Optional() @Inject(APP_BASE_HREF) private baseHref: string) { }

  documentationLoaded = false;
  @ViewChild('emailLink') emailLink: ElementRef;
  selectedApplication = new FormControl(null);
  documentation = { Documentation: { Apps: [] } };
  //application name
  name: any;
  //version object
  version: any;
  //version value
  versionValue: any;
  matrix = { Features: [] };
  test: any;

  ngOnInit() {
    this.getApiDocs();
    this.startSubscriptions();
  }

  openDocs() {
    console.log('baseHref', this.baseHref);
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`${this.baseHref}doc-viewer`, this.name, this.versionValue])
    );
    window.open(url, '_blank');
  }

  startSubscriptions() {
    this.selectedApplication.valueChanges.subscribe((value: any) => {
      this.version = value;
      this.getMatrixDocs(value.Version);
    });
  }
  selectChange(event) {
    const selectedIndex = event.target.selectedIndex;
    this.versionValue = event.target[selectedIndex].text;
    this.name = event.target.options[selectedIndex].parentNode.getAttribute('label');

  }

  getApiDocs() {
    this.documentationService.getApiSpecs().subscribe(x => {
      this.documentation = x['Documentation'];
    });
  }
  getMatrixDocs(version) {
    this.documentationService.getMatrixSpecs(version).subscribe((x: { Matrix: { Features: any[] } }) => {
      if (x.Matrix) {
        x.Matrix.Features.sort((a, b) => a['Name'].toLowerCase().localeCompare(b['Name'].toLowerCase()));
      }
      this.matrix = x.Matrix;
    });
  }

  sendEmail() {
    this.emailLink.nativeElement.setAttribute('href', 'mailto:courtstack@lacourt.org');
    this.emailLink.nativeElement.click();
    this.emailLink.nativeElement.remove();
  }
}
