<section class="background-gradient">
    <div class="container p-4">
      <div class="row">
        <div class="col-lg-12">
          <h2 class="text-white text-center p-4">Releases</h2>
        </div>
      </div>
    </div>
  </section>
  <section class="release">
    <div class="container-fluid p-5">
      <div class="row">
        <div class="col-lg-12">
          <ul class="nav nav-pills flex-column flex-sm-row align-items-start" id="myTab" role="tablist">
            <li class="nav-item">
              <a class="nav-link link active" data-toggle="tab" role="button" (click)="filterRelease(null)"
              aria-controls="all" aria-selected="true">All</a>
            </li>
            <li class="nav-item" *ngFor="let group of $groups | async">
              <a class="nav-link link" data-toggle="tab" role="button" (click)="filterRelease(group)" aria-controls="group" aria-selected="false">{{group}}</a>
            </li>
          </ul>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 p-3" *ngFor="let release of $filteredRelease | async">
          <div class="card shadow p-2">
            <div class="card-body">
              <div class="text-left pt-2">
                <h5 class="mb-0" *ngIf="release.title">{{release.title}}</h5>
                <p>
                    {{release.description}} -
                <b>
                  {{release.releaseDate}}
                </b>
                </p>
                <a  *ngIf="release.releaseUrl" class="card-link text-secondary link" [ngClass]="{'hide': release.releaseUrl === null}" target="_blank"
                  href="{{release.releaseUrl}}">Release Announcement</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
