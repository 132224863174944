import { trigger, transition, animate, style, state, group } from '@angular/animations';

export const openClose = trigger('openClose', [
    transition(':enter', [
      style({transform: 'translateY(-100%)'}),
      animate('200ms ease-in', style({transform: 'translateY(0%)', overflow: 'hidden'}))
    ]),
    transition(':leave', [
      animate('200ms ease-in', style({transform: 'translateY(-50%)', overflow: 'hidden'}))
    ])
  ]);




