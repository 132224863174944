import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class LearnService {

    constructor(public http: HttpClient) { }

    getClasses() {
        return this.http.get('assets/classes.json').pipe(
            map((classConfig: any) => classConfig.classes.filter((class1: any) => class1.active)
            .sort((class1: any, class2: any) => (class1.new === class2.new) ? 0 : class1.new ? -1 : 1 ))
        );
    }

    getGroups() {
        return this.http.get('assets/classes.json').pipe(
            map((classConfig: any) => classConfig.groups)
        );
    }

    filterClassesByGroup($classes, group) {
        return group ?
            $classes.pipe(
                map(
                    (classes: any) => classes
                        .filter(class1 => class1.groups.indexOf(group) !== -1)
                )
            ) :
            $classes.pipe(
                map(
                    (classes: any) => classes
                )
            );
    }

}
