import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Observable } from 'rxjs';
import { LearnService } from './learn.service';

@Component({
  selector: 'app-learn',
  templateUrl: './learn.component.html',
  styleUrls: ['./learn.component.css']
})
export class LearnComponent implements OnInit {

  $classes: Observable<any>;
  $groups: Observable<any>;
  $filteredClasses: Observable<any>;

  selectedClass: any = null;
  @ViewChild('description') element:ElementRef;

  constructor(private learnService: LearnService) { }

  ngOnInit() {
    this.getClasses();
    this.getGroups();
  }

  getClasses() {
    this.$classes = this.learnService.getClasses();
    this.filterClasses(null);
  }

  selectClass (classObj) {
    this.selectedClass = classObj;
    this.element.nativeElement.insertAdjacentHTML('beforeend', this.getHtml());
  }

  getUrl(classObj: any) {
    return 'url(\'' + classObj.coverImgBackground + '\')';
  }

  navigate(classObj) {
    window.open(classObj.actionLink);
  }

  getHtml() {
    if (this.selectedClass?.description) {
      return this.selectedClass?.description;
    }
  }

  getGroups() {
    this.$groups = this.learnService.getGroups();
  }

  filterClasses(group) {
    this.$filteredClasses = this.learnService.filterClassesByGroup(this.$classes, group);
  }

}
